.plans {
	&__desktop {
		margin-top: 10rem;
		h2 {
			margin-left: 19rem;
			max-width: 36rem;
			font-size: 3.75rem;
			margin-bottom: 4rem;
		}

		table {
			width: 100%;
			border-spacing: 0rem;
			th {
				text-align: start;
				height: 20rem;
				padding: 1rem;
			}

			tbody {
				td {
					padding: 1rem;
					p {
						max-width: 16rem;
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 1.5rem;
			height: 100%;
			h4 {
				margin-bottom: 3rem;
				text-wrap: nowrap;
			}

			h3 {
				span {
					font-size: 1rem;
					font-weight: 400;
				}
			}

			p {
				font-size: 1rem;
				font-weight: 400;
				max-width: 16rem;
			}

			&__selection {
				display: flex;
				justify-content: flex-start;
				margin-bottom: 1rem;
				gap: 3rem;

				div {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 0.5rem;
				}
			}
		}
	}

	&__mobile {
		display: none;
	}
}
.plans__desktop__card__selection input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 18px;
	height: 18px;
	border: none;
	background-color: var(--accent-color);
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	position: relative;
	transition: border 0.2s, background-color 0.2s;
}

.plans__desktop__card__selection input[type="checkbox"]:checked {
	border-color: black;
}

.plans__desktop__card__selection input[type="checkbox"]:checked::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: black;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}

.center {
	text-align: center;
}
.center {
	button {
		width: 100%;
	}
}
tbody tr:nth-child(even) {
	background-color: #f9fafb;
}

.no-bg {
	background-color: transparent !important;
}

@media only screen and (max-width: 1250px) {
	.plans {
		&__mobile {
			display: block;
		}

		&__desktop {
			display: none;
		}
	}
}
