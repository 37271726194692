.magic-formula {
	margin-top: 8rem;
	h2 {
		margin-bottom: 2.5rem;
		background-color: #f3f3f3;
		border-radius: 0.438rem;
		display: inline-block;
		padding: 0.5rem;
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2.5rem;

		&__item {
			background-color: #f3f3f3;
			border-radius: 2.813rem;
			padding: 2.5rem;
			position: relative;

			h4 {
				background-color: #f3f3f3;
				padding: 0.5rem;
				display: inline-block;
				border-radius: 0.438rem;
			}

			p {
				margin: 1.5rem 0 3.813rem 0;
				max-width: 25.25rem;
			}

			div {
				background-color: var(--primary-color);
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 900;
				color: var(--accent-color);
			}

			img {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}

		&__item:nth-of-type(1) {
			h4 {
				background-color: var(--accent-color);
			}
		}

		&__item:nth-of-type(2) {
			background-color: var(--accent-color);
		}

		&__item:nth-of-type(3) {
			background-color: var(--primary-color);

			p {
				color: white;
			}

			div {
				background-color: white;
				color: var(--primary-color);
			}
		}

		&__item:nth-of-type(4) {
			h4 {
				background-color: var(--accent-color);
			}
		}
	}
}

@media only screen and (max-width: 1300px) {
	.magic-formula {
		&__grid {
			&__item {
				p {
					max-width: 80%;
				}

				img {
					position: absolute;
					right: 0;
					bottom: 0;
					max-width: 30%;
				}
			}

			&__item:nth-of-type(1) {
				img {
					max-width: 50%;
				}
			}
		}
	}
}

@media only screen and (max-width: 1100px) {
	.magic-formula {
		margin-top: 5rem;
		h2 {
			margin-bottom: 2.5rem;
			background-color: #f3f3f3;
			border-radius: 0.438rem;
			display: inline-block;
			padding: 0.5rem;
		}

		&__grid {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;

			&__item {
				p {
					max-width: 80%;
				}

				img {
					position: absolute;
					right: 0;
					bottom: 0;
					max-width: 30%;
				}
			}

			&__item:nth-of-type(1) {
				img {
					max-width: 50%;
				}
			}
		}
	}
}

@media only screen and (max-width: 650px) {
	.magic-formula {
		&__grid {
			&__item {
				p {
					max-width: 100%;
				}
			}
		}
	}
}
