.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__desktop {
		display: flex;
		align-items: center;
		gap: 2.5rem;

		li {
			list-style-type: none;
			font-size: 1.25rem;
		}
	}

	&__mobile-menu-icon {
		display: none;
	}

	&__logo {
		z-index: 5;
	}

	&__mobile {
		display: none;
	}
}

@media only screen and (max-width: 1100px) {
	.navbar {
		&__logo {
			max-width: 20%;
		}
	}
}

@media only screen and (max-width: 800px) {
	.navbar {
		&__desktop {
			display: none;
		}

		&__mobile-menu-icon {
			display: block;
			z-index: 5;
		}

		&__mobile {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			width: 100vw;
			height: 70vh;
			position: absolute;
			top: 0;
			left: 0;
			background-color: white;

			li {
				list-style-type: none;
				font-size: 2.5rem;
			}

			button {
				max-width: 60%;
			}
		}
	}
}

@media only screen and (max-width: 650px) {
	.navbar {
		&__mobile {
			height: 100vh;

			button {
				max-width: 80%;
			}
		}

		&__logo {
			max-width: 40%;
		}
	}
}
