.why-us {
	max-width: 36.25rem;
	margin: 10rem auto 6rem auto;
	text-align: center;

	h2 {
		background-color: var(--accent-color);
		padding: 0.5rem;
		display: inline-block;
		border-radius: 0.438rem;
		margin-bottom: 2.5rem;
	}
}

@media only screen and (max-width: 650px) {
	.why-us {
		max-width: 36.25rem;
		margin: 8rem auto 6rem auto;
		text-align: center;

		h2 {
			background-color: var(--accent-color);
			padding: 0.5rem;
			display: inline-block;
			border-radius: 0.438rem;
			margin-bottom: 2.5rem;
			font-size: 2.5rem;
		}
	}
}
