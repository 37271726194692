.elite {
	margin-top: 6.5rem;

	&__cta {
		display: flex;
		justify-content: space-between;
		background-color: #f3f3f3;
		padding: 3.563rem 0 3.563rem 3.75rem;
		align-items: center;
		border-radius: 2.813rem;
		position: relative;

		&__text {
			max-width: 33rem;

			p {
				margin: 1.625rem 0;
			}
		}

		img {
			position: absolute;
			right: 8.5rem;
			top: -4rem;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.elite {
		&__cta {
			&__text {
				max-width: 25rem;
			}

			img {
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				max-width: 30%;
			}
		}
	}
}

@media only screen and (max-width: 650px) {
	.elite {
		margin-top: 3.5rem;

		&__top {
			flex-direction: column;

			h3 {
				max-width: 60%;
				align-self: flex-start;
			}
			p {
				max-width: 100%;
			}
		}

		&__cta {
			flex-direction: column;
			background-color: #f3f3f3;
			padding: 1.5rem;

			&__text {
				max-width: 100%;
			}

			button {
				width: 100%;
			}

			img {
				position: relative;
				max-width: 100%;
				top: 0%;
				transform: translateY(0%);
				margin-top: 1rem;
			}
		}
	}
}
