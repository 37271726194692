.cta-one {
	margin-top: 6.5rem;

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8rem;
		gap: 2rem;

		h3 {
			background-color: var(--accent-color);
			border-radius: 0.438rem;
			padding: 0.4rem;
		}
		p {
			max-width: 44.75rem;
		}
	}

	&__cta {
		display: flex;
		justify-content: space-between;
		background-color: #f3f3f3;
		padding: 5.563rem 0 5.563rem 3.75rem;
		align-items: center;
		border-radius: 2.813rem;
		position: relative;

		&__text {
			max-width: 33rem;

			h3 {
				margin-bottom: 1.625rem;
			}
		}

		img {
			position: absolute;
			right: 0;
			top: -4rem;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.cta-one {
		&__top {
			p {
				max-width: 25rem;
			}
		}

		&__cta {
			&__text {
				max-width: 25rem;
			}

			img {
				top: 50%;
				transform: translateY(-50%);
				max-width: 30%;
			}
		}
	}
}

@media only screen and (max-width: 650px) {
	.cta-one {
		margin-top: 3.5rem;

		&__top {
			flex-direction: column;

			h3 {
				max-width: 60%;
				align-self: flex-start;
			}
			p {
				max-width: 100%;
			}
		}

		&__cta {
			flex-direction: column;
			background-color: #f3f3f3;
			padding: 1.5rem;

			&__text {
				max-width: 100%;
			}

			img {
				position: relative;
				max-width: 100%;
				top: 0%;
				transform: translateY(0%);
				margin-top: 1rem;
			}
		}
	}
}
