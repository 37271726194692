.reviews {
	margin-top: 5rem;
	img {
		max-width: 100%;
	}

	.smaller {
		margin-top: 5rem;
	}
}
