.contact-us {
	margin-top: 6.5rem;
	background-color: var(--accent-color);
	border-top-left-radius: 8rem;
	border-top-right-radius: 8rem;
	position: relative;
	min-height: 25.5rem;
	text-align: center;
	overflow-x: hidden;

	h2 {
		padding-top: 4rem;
	}

	button {
		margin-top: 2.1rem;
	}

	p {
		position: absolute;
		bottom: 1rem;
		left: 50%;
		transform: translateX(-50%);

		a {
			color: #115bc9;
		}
	}

	img {
		position: absolute;
		bottom: 0;
		right: -8rem;
	}
}

@media only screen and (max-width: 1100px) {
	.contact-us {
		p {
			position: absolute;
			bottom: 1rem;
			left: 50%;
			transform: translateX(-50%);

			a {
				color: #115bc9;
			}
		}

		img {
			position: absolute;
			bottom: 0;
			max-width: 40%;
			right: -5rem;
		}
	}
}

@media only screen and (max-width: 650px) {
	.contact-us {
		margin-top: 5rem;
		border-top-left-radius: 6rem;
		border-top-right-radius: 6rem;
		min-height: 20.5rem;

		img {
			display: none;
		}
	}
}
