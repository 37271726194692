.mobile-plans {
	&__selection {
		display: flex;
		max-width: 80%;
		justify-content: center;
		margin: 0 auto 2rem auto;
		gap: 2rem;

		button {
			padding: 1rem 2.188rem;
			font-size: 1.25rem;
			border-radius: 0.875rem;
			border: none;
			font-weight: 700;
			cursor: pointer;
		}
	}
	margin-top: 10rem;
	h2 {
		max-width: 36rem;
		font-size: 3.75rem;
		margin: 0 auto 4rem auto;
		text-align: center;
	}

	table {
		width: 100%;
		border-spacing: 0rem;
		th {
			text-align: start;
			height: 20rem;
			padding: 1rem;
		}

		tbody {
			td {
				padding: 1rem;
				p {
					max-width: 25rem;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 1.5rem;
		height: 100%;
		h4 {
			margin-bottom: 2rem;
			text-wrap: nowrap;
		}

		h3 {
			span {
				font-size: 1rem;
				font-weight: 400;
			}
		}

		p {
			font-size: 1rem;
			font-weight: 400;
			max-width: 16rem;
		}

		&__selection {
			display: flex;
			margin-bottom: 1rem;
			justify-content: flex-start;
			margin-bottom: 1rem;
			gap: 3rem;

			div {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.5rem;
			}
		}
	}
}
.mobile-plans__card__selection input[type="checkbox"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 18px;
	height: 18px;
	border: none;
	background-color: var(--accent-color);
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	position: relative;
	transition: border 0.2s, background-color 0.2s;
}

.mobile-plans__card__selection input[type="checkbox"]:checked {
	border-color: black;
}

.mobile-plans__card__selection input[type="checkbox"]:checked::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: black;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}

.center {
	text-align: center;
}
.center {
	button {
		width: 100%;
	}
}
tbody tr:nth-child(even) {
	background-color: #f9fafb;
}

.no-bg {
	background-color: transparent !important;
}

.hide {
	display: none;
}

.active {
	background-color: var(--accent-color);
}

@media only screen and (max-width: 650px) {
	.mobile-plans {
		&__selection {
			max-width: 100%;
			flex-direction: column;
			gap: 1rem;

			button {
				width: 100%;
				border: 1px solid var(--primary-color);
				background-color: transparent;
			}
		}

		.active {
			background-color: var(--accent-color);
		}
		margin-top: 8rem;

		h2 {
			max-width: 100%;
			font-size: 2rem;
			margin: 0 auto 2rem auto;
			text-align: left;
		}

		table {
			th {
				text-align: start;
				height: 20rem;
				padding: 1rem;
			}

			tbody {
				td {
					padding: 1rem;
					p {
						max-width: 25rem;
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}

		&__card {
			justify-content: center;
			gap: 3rem;
			height: 100%;

			p {
				margin-top: 0.5rem;
				max-width: 100%;
			}

			button {
				width: 100%;
				margin-bottom: 2rem;
			}
		}
	}
}
