.hero {
	display: flex;
	justify-content: space-between;
	margin-top: 4.375rem;
	align-items: center;

	&__text {
		max-width: 33.75rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		p {
			margin: 2.188rem 0 1rem 0;
			max-width: 28rem;
		}
		span {
			font-style: italic;
			font-weight: 600;
		}
		button {
			margin-top: 2rem;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.hero {
		img {
			max-width: 35%;
		}
	}
}

@media only screen and (max-width: 650px) {
	.hero {
		flex-direction: column;

		img {
			max-width: 100%;
			margin-top: 4rem;
		}

		&__text {
			max-width: 100%;
			p {
				max-width: 100%;
			}
			span {
				font-weight: 400;
				max-width: 60%;
			}
			button {
				width: 100%;
			}
		}
	}
}
