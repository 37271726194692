@import url('https://fonts.googleapis.com/css2?family=Suwannaphum:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --header-font: "Suwannaphum", serif;
  --text-font: "Inter", sans-serif;
  ;
  --primary-color: black;
  --accent-color: #92F2DB;
}


*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--header-font);
  color: var(--primary-color);
}

body {
  margin: 2rem auto 0 auto;
  max-width: 90rem;
  overflow-x: hidden;
}


p {
  font-size: 1.125rem;
  line-height: 120%;
  font-family: var(--text-font);
}

h1 {
  font-size: 4.375rem;
  line-height: 100%;
  font-weight: 900;
}

h2 {
  font-size: 2.5rem;
  line-height: 120%;
  font-weight: 700;
}

h3 {
  font-size: 1.875rem;
  line-height: 120%;
}

h4 {
  font-size: 1.5rem;
  line-height: 120%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  border: none;
  background-color: transparent;
}

button a {
  padding: 1rem 2.188rem;
  font-size: 1.25rem;
  border-radius: 0.875rem;
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.button-dark a {
  background-color: var(--primary-color);
  color: white;
}

.button-dark a {
  color: white;
}


.button-light a {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.button-dark a:active,
.button-light a:active {
  background-color: var(--accent-color);
  color: var(--primary-color);

  a {
    color: var(--primary-color);
    font-size: 1.25rem;
    border-radius: 0.875rem;
    border: none;
    font-weight: 700;
  }
}


@media only screen and (max-width: 90rem) {
  body {
    padding: 0 6.25rem;
  }
}

@media only screen and (max-width: 1100px) {
  body {
    padding: 0 2.5rem;
  }

  h1 {
    font-size: 3rem;
  }

  button a {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.875rem;
    border: none;
    font-weight: 700;
    cursor: pointer;
  }

}

@media only screen and (max-width: 800px) {
  body {
    padding: 0 1.25rem;
  }

  p {
    font-size: 1.125rem;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 120%;
    font-weight: 700;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }



  button a {
    padding: 1rem 2.188rem;
    font-size: 1.25rem;
    border-radius: 0.875rem;
    border: none;
    font-weight: 700;
    cursor: pointer;
    width: 90%;
  }

}