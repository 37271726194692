.founder {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3.5rem;

	div {
		h2 {
			background-color: var(--accent-color);
			padding: 0.5rem;
			border-radius: 0.438rem;
			margin-bottom: 2.5rem;
			display: inline-block;
		}
		a {
			color: #115bc9;
		}
		p {
			max-width: 37.5rem;
			margin-bottom: 1rem;
			font-style: italic;
		}

		a {
			img {
				width: 3rem;
				margin-top: 0.5rem;
			}
		}

		.arrow-container {
			display: flex;
			gap: 0.2rem;
		}
	}
}

@media only screen and (max-width: 650px) {
	.founder {
		flex-direction: column;

		img {
			max-width: 50%;
		}

		div {
			text-align: center;
			h2 {
				margin-bottom: 1rem;
			}

			.arrow-container {
				justify-content: center;
			}
		}
	}
}
